import React from "react"
import { Footer } from "../components"
import "../styles/style.css"

const Layout = ({ children, footer }) => {
  return (
    <div className="container">
      {children}
      <Footer footer={footer} />
    </div>
  )
}

export default Layout
